// -------------------------------------------
// Google Tag Manager
// -------------------------------------------

var dataLayer = [];

// ------------------------------
// GAF Google Tag Manager
//------------------------------
// uncomment for release to Production

if (window.googleGAFTagUrl) {
    console.log('GAF gtm inserting')
    window.googleGAFTagUrl = window.googleGAFTagUrl.replace('prod', '');
    var gafTagUrl = 'https://www.googletagmanager.com/gtm.js?id=';
    gafTagUrl = gafTagUrl.replace('prod', '');
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl + decodeURI(window.googleGAFTagUrl); j.onload = function () {
                    LoadITAGTMManager();
                }; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5XX679M');
}
else {
    LoadITAGTMManager();
}

function LoadITAGTMManager() {
    setTimeout(function () {
        console.log('inserting ita stuff.')
        if (window.googleTagManagerContainerId) {
            var googleTagManagerContainerId = window.googleTagManagerContainerId;
            var googleTagManagerRoleInfo = window.googleTagManagerRoleInfo;
            var googleTagManagerPersonId = window.googleTagManagerPersonId;

            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.appendChild(j);
            })(window, document, 'script', 'dataLayer', googleTagManagerContainerId);
        }
        // Prepare Data to send to GTM 
        dataLayer.push({
            'PersonId': googleTagManagerPersonId,
            'Groups': googleTagManagerRoleInfo
        });
        // Once Data is ready trigger "variablesSet" event that GTM is listening for.
        dataLayer.push({ 'event': 'variablesSet' });
    }, 20);
}

